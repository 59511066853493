<script setup lang="ts">
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';

// Components
import { useI18n } from 'vue-i18n';

dayjs.extend(isBetween);

// Composables
const router = useRouter();
const route = useRoute();
const { t } = useI18n();

// Store
const appService = appStore();

// Data
const modalAreaSelector = ref(false);

// Methods
const setDate = (event: { start: Date; end: Date }) => {
  appStore().setExploreDateRange({ start: event.start, end: event.end });
};

onMounted(() => {
  appStore().setMeter(parseInt(router.currentRoute.value.params.id as string));
});

// Computed
const tabs = computed(() => {
  const tabs: { label: string; to: string }[] = [
    {
      to: '/explore/consumption/' + meter.value?.id,
      label: t('global.consumption'),
    },
  ];

  if (!isOffProdHidden.value) {
    tabs.push({
      to: '/explore/off-production/' + meter.value?.id,
      label: t('off_production.off_production'),
    });
  }

  if (!isIPEHidden.value) {
    tabs.push({
      to: '/explore/fabrication-orders/' + meter.value?.id,
      label: t('fabrication_order.fabrication_orders'),
    });
  }

  return tabs;
});

const meter = computed(() => {
  const meterId = appStore().getMeter || parseInt(route.params.id as string);
  return meterStore().getMeter(meterId);
});

const isOffProdHidden = computed(() => {
  return meter.value?.meter_type.name === 'grdf';
});

const isIPEHidden = computed(() => {
  const meterId = meter.value?.id;
  const area = areaStore().getAreaByMeterId(meterId);
  return area?.area_type !== 'machine';
});

const formatDateForDateSelector = computed(() => {
  return {
    start: dayjs(appService.getExploreDateRange.start).toDate(),
    end: dayjs(appService.getExploreDateRange.end).toDate(),
  };
});

const dateRange = computed(() => {
  return {
    start: dayjs(appService.getExploreDateRange.start).format('YYYY-MM-DD'),
    end: dayjs(appService.getExploreDateRange.end).format('YYYY-MM-DD'),
  };
});
</script>

<template>
  <ui-page :title="meter?.area.name + ' - ' + t(`global.energy_type.${meter?.meter_type.energy_type}`)" :tabs="tabs">
    <template #header-actions>
      <ui-period-selector :model-value="formatDateForDateSelector" @update-period="setDate">
        {{ t('global.period') }}
      </ui-period-selector>
      <menu-area-selector v-model="modalAreaSelector" />
    </template>
    <template #default>
      <nuxt-page :date-range="dateRange" :data-type="meter?.meter_type.energy_type" @update-date-range="dateRange = $event" />
    </template>
  </ui-page>
</template>
